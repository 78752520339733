import {
  DEFAULT_FONT,
  detectLanguage,
  JsPdfLanguage,
  specialLanguage
} from "@/app/infrastructures/misc/common-library/DetectLanguage";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";
import { limitLengthName } from "../../module";

export const senderRecipientFooter = async (params: {
  doc: jsPDF | any;
  bookingType: string;
  accountType: string;
  data: PrintDataEntitas;
  pieceData: any;
  barcode: string;
  vendorRefCode: string;
  barcodeValue: string;
  vendorRefCodeValue: string;
  logo: string;
  shipmentId: string;
  startX: number;
  startY: number;
}) => {
  let fontSender = DEFAULT_FONT
  const isInterpack = params.data.sttProductType.toUpperCase() === "INTERPACK"
  
  if (isInterpack) {
    fontSender = await detectLanguage(
      new JsPdfLanguage({
        text: params.data.sttSenderName,
        product: params.data.sttProductType,
        jsPdf: params.doc
      })
    );
  }
  let fontRecipient = DEFAULT_FONT
  if (isInterpack) {
    fontRecipient = await detectLanguage(
      new JsPdfLanguage({
        text: params.data.sttRecipientName,
        product: params.data.sttProductType,
        jsPdf: params.doc
      })
    );
  }
  const getMaxWidth = (language: string) =>
    specialLanguage(language) ? 13 : 19;

  const sttSenderNameData =
    params.doc.splitTextToSize(
      limitLengthName(params.data.sttSenderName, getMaxWidth(fontSender) + 1),
      getMaxWidth(fontSender)
    )?.[0] || "";
  const sttRecipient =
    params.doc.splitTextToSize(
      limitLengthName(
        params.data.sttRecipientName,
        getMaxWidth(fontRecipient) + 1
      ),
      getMaxWidth(fontRecipient)
    )?.[0] || "";

  params.doc.setFont(fontSender, "bold");
  params.doc.setFontSize(5);
  params.doc.text(sttSenderNameData, params.startX + 15, params.startY);

  params.doc.setFont(fontRecipient, "bold");
  params.doc.setFontSize(5);
  params.doc.text(sttRecipient, params.startX + 15, params.startY + 2.5);
};
