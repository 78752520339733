import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import JsBarcode from "jsbarcode";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import { vendorPickupDetail } from "@/app/ui/modules/receipt-v2/module";
import { createRoundRect } from "./roundRect";
import { DeliveryAddress } from "./delivery-address";
import { priorityPrint } from "../../constants/images";

export const DeliverySection = async (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function,
  language = "id"
) => {
  const leftMargin = params.startX + 5;
  const rightEndX = params.startX + 77;

  const { phoneRecipientPositionY, senderPositionY } = await DeliveryAddress(
    params,
    translate
  );

  // DESTINATION DISTRICT NAME
  const districtPositionY = phoneRecipientPositionY + 13.5;

  params.doc.setFontSize(8);
  params.doc.setFont("Montserrat", "bold");

  const splitedSTTDestinationDistrictName = params.doc.splitTextToSize(
    params.data.sttDestinationDistrictName.toUpperCase(),
    65
  );

  params.doc.text(
    splitedSTTDestinationDistrictName,
    leftMargin,
    districtPositionY
  );

  // FLAG STT
  let sttPositionX = leftMargin;
  const sttPositionY = districtPositionY + 6;
  params.data.sttFlag.forEach((item: string) => {
    const rect = createRoundRect(params.doc, item, sttPositionX, sttPositionY);
    sttPositionX += rect.w + 2;
  });

  // COMMODITY
  const sttCommodityNamelanguage =
    language === "en"
      ? params.data.sttCommodityDescription
      : params.data.sttCommodityName;

  const sttCommodityNameString =
    sttCommodityNamelanguage.length > 30
      ? `${sttCommodityNamelanguage.substring(0, 30)}...`
      : sttCommodityNamelanguage;

  const sttCommodityName = params.doc.splitTextToSize(
    sttCommodityNameString,
    50
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(6);
  params.doc.text(
    sttCommodityName,
    rightEndX - 1,
    sttPositionY + 2,
    null,
    null,
    "right"
  );

  // AWB Vendor - static position
  const awbPositionY = params.startY + 84;
  const barcodePositionY = awbPositionY + 1;
  const sttVendorRefferenceNo = params.data.sttVendorRefferenceNo;
  if (sttVendorRefferenceNo !== "") {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setFontSize(9);
    params.doc.text(sttVendorRefferenceNo, leftMargin, awbPositionY);

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, sttVendorRefferenceNo, {
      displayValue: false,
      margin: 0
    });
    const jpegUrl = canvas.toDataURL("image/jpeg");
    params.doc.addImage(
      jpegUrl,
      "PNG",
      leftMargin + 2,
      barcodePositionY,
      params.doc.getTextDimensions(sttVendorRefferenceNo).w,
      5,
      uuid(),
      "FAST",
      0
    );
  }

  if (params.data.isPriorityTier) {
    const priorityIcon = priorityPrint

    params.doc.addImage(
      priorityIcon,
      "PNG",
      rightEndX - 7,
      barcodePositionY,
      5,
      5,
      "logo-priority-print",
      "FAST"
    );
  }

  const vendorName = vendorPickupDetail(
    params.data.sttVendorName,
    params.data.sttDistrictType
  );

  if (vendorName) {
    const vendorRightMargin = params.data.isPriorityTier ? 15 : 9;
    createRoundRect(
      params.doc,
      vendorName,
      rightEndX - vendorRightMargin,
      barcodePositionY
    );
  }

  // Bottom line - static position
  const endContentY = params.startY + 92;
  params.doc.setLineDash([], 0);
  params.doc.setLineWidth(0.4);
  params.doc.setDrawColor("#000000");
  params.doc.line(rightEndX, senderPositionY - 5, rightEndX, endContentY);
  params.doc.line(
    params.startX + 0,
    endContentY,
    params.startX + 105,
    endContentY
  );

  return {
    ...params,
    startX: rightEndX,
    startY: endContentY
  };
};
