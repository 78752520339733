import startCase from "lodash/startCase";
import { maskPhoneV2, limitLengthName } from "@/app/ui/modules/receipt-v2/module";
import { DEFAULT_FONT, detectLanguage, JsPdfLanguage } from "@/app/infrastructures/misc/common-library/DetectLanguage";

export const DeliverySenderReceiverSection = async (
  params: any,
  translate: Function
) => {
  const leftMargin = params.startX + 5;
  params.doc.setTextColor("#333333");

  // PENGIRIM
  const senderPositionY = params.startY + 40 + 4;

  const sttSenderNameData = limitLengthName(params.data.sttSenderName, 60);
  const sttSenderText =
    startCase(translate("receipt.shipper")).toUpperCase() +
    ": " +
    sttSenderNameData.toUpperCase() +
    " " +
    maskPhoneV2(params.data.sttSenderPhone);

  let fontLanguage = DEFAULT_FONT
  const isInterpack = params.data.sttProductType.toUpperCase() === "INTERPACK"
  if (isInterpack) {
    fontLanguage = await detectLanguage(
      new JsPdfLanguage({
        text: params.data.sttSenderName,
        product: params.data.sttProductType,
        jsPdf: params.doc
      })
    );
  }

  params.doc.setFont(
    fontLanguage,
    "bold"
  );
  params.doc.setFontSize(6);
  const sttSenderTextSplitted = params.doc.splitTextToSize(sttSenderText, 70);

  params.doc.text(sttSenderTextSplitted, leftMargin, senderPositionY);

  // PENERIMA
  const sttSenderTextSplittedHeight = params.doc.getTextDimensions(
    sttSenderTextSplitted
  ).h;
  const sttRecipientName = limitLengthName(params.data.sttRecipientName, 85);
  const recipientPositionY = senderPositionY + sttSenderTextSplittedHeight + 1;
  const sttRecipientText =
    startCase(translate("receipt.receiver")).toUpperCase() +
    ": " +
    sttRecipientName.toUpperCase();

  const sttRecipientTextSplitted = params.doc.splitTextToSize(
    sttRecipientText,
    70
  );

  params.doc.setFont(
    await detectLanguage(
      new JsPdfLanguage({
        text: params.data.sttRecipientName,
        product: params.data.sttProductType,
        jsPdf: params.doc
      })
    ),
    "bold"
  );
  params.doc.setFontSize(6);
  params.doc.text(sttRecipientTextSplitted, leftMargin, recipientPositionY);

  return { senderPositionY, recipientPositionY, sttRecipientTextSplitted };
};
