import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { blackCut } from "../../constants/images";

export const RecipientRipperFooterSection = async (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function
) => {
  const leftMargin = params.startX + 4;

  params.doc.setTextColor("#333333");
  params.doc.setFontSize(5);

  const snkPositionY = params.startY + 104;
  params.doc.setFont("Montserrat", "normal");
  params.doc.text(translate("receipt.terms"), leftMargin, snkPositionY);
  params.doc.text(translate("receipt.info"), params.startX + 101, snkPositionY, null, null, "right");

  params.doc.setLineDash([1]);
  params.doc.setDrawColor("#A7A8AA");
  params.doc.setLineWidth(0.5);
  params.doc.line(
    params.startX,
    snkPositionY + 2,
    params.startX + 105,
    snkPositionY + 2
  );

  const cut = blackCut;
  params.doc.addImage(
    cut,
    "PNG",
    leftMargin,
    snkPositionY + 0.5,
    2.5,
    2.5,
    "cut",
    "FAST"
  );
};
