/* eslint-disable @typescript-eslint/camelcase */
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { headerSection } from "./sections/header";
import { originDestination } from "./sections/origin";
import { allPrices, variablesDefine } from "./part-layout/parts";
import { ursaSla } from "./sections/ursa-sla";
import { weights } from "./sections/weight";

import { DeliverySection } from "./sections/delivery";
import { RecipientFooterSection } from "./sections/recipient-footer";
import { QuoteSection } from "./sections/quote";
import { TarifSection } from "./sections/tariff";
import { KoliSection } from "./sections/koli";
import { getWaterMark } from "./sections/watermark";
import { RecipientRipperFooterSection } from "./sections/ripper";
import { RecipientBreakdownSection } from "./sections/recipient-breakdown";
import { viralSection } from "./sections/viral";
import { RecipientHeaderSection } from "./sections/recipient-header";
import { RecipientDestinationSection } from "./sections/recipient-destination";

export const newReceipt = async (
  params: {
    doc: any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  language = "id"
) => {
  // LANGUAGE
  const i18nInstance = createI18n({
    legacy: false,
    locale: language,
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  const translate = i18nInstance.global.t;

  const { doc, data, barcode, barcodeValue, logo, startX, startY, shipmentId, bookingType, accountType, pieceData } = params;
  const { logoAspectRatio, showCodRetail, isDfod, shipmentAlgo } = await variablesDefine(doc, data, { shipmentId, logo, bookingType, accountType })
  const { estimateSla, isBigpackVolume, isJumbopackvolume, } = allPrices(data, { showCodRetail, isDfod, language });

  //import here any components
  let newParams = params;
  await getWaterMark(newParams);
  newParams = await headerSection(newParams, logoAspectRatio);
  originDestination(newParams, { startX, startY, })
  ursaSla(newParams, estimateSla,{ startX, startY, }, translate)
  weights(newParams, { startX, startY, }, isBigpackVolume, isJumbopackvolume,)
  await DeliverySection(params, translate, language);
  KoliSection(params, translate);
  await RecipientRipperFooterSection(params, translate);
  RecipientBreakdownSection(params, translate);
  RecipientFooterSection(params, translate);
  await viralSection(newParams, { startX, startY, }, translate)
  // here should be a tarif section
  TarifSection(params, translate);
  // here should be quote section
  QuoteSection(params, translate);
  await RecipientHeaderSection(params, logoAspectRatio, language);
  await RecipientDestinationSection(params, estimateSla, translate);
};
